import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { ReportData, ReportRankComparison, ReportSettings } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { ReportsTemplateDataChangeEvent, ReportsTemplateFormValue, ReportsTemplateViewSettingsAppliedEvent } from '../template/reports-template.component';
import { map, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'reports-rank-comparison',
    templateUrl: './reports-rank-comparison.component.html'
})
export class ReportsRankComparisonComponent implements OnInit, OnDestroy {

    public form: FormGroup;

    public viewSettings$ = new BehaviorSubject<ReportSettings>(null);

    public report: PortalReport;
    public data: ReportData;

    private viewSettingsFormValue$ = new Subject<ReportsTemplateFormValue>();
    private destroy$ = new Subject<void>();

    ngOnInit(): void {

        this.form = new FormGroup({
            'timeFilter': new FormControl(null),
            'comparisonFilter': new FormControl(null),
            'filters': new FormControl([]),
            'selectedBenchmark': new FormControl(null),
            'selectedStore': new FormControl(null),
        });

        this.viewSettingsFormValue$
            .pipe(
                map(settings => toViewSettings(settings)),
                takeUntil(this.destroy$)
            ).subscribe(settings => {
            this.viewSettings$.next(settings);
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onViewSettingsApplied(event: ReportsTemplateViewSettingsAppliedEvent) {
        const settings = event.viewSettings || event.report.settings;

        this.form.get('timeFilter').setValue(settings.rankComparison.timeFilter);
        this.form.get('comparisonFilter').setValue(settings.rankComparison.comparisonFilter);
        this.form.get('filters').setValue(settings.rankComparison.filters || []);
        this.form.get('selectedBenchmark').setValue(settings.rankComparison.selectedBenchmark);
        this.form.get('selectedStore').setValue(settings.rankComparison.selectedStore);
    }

    onViewSettingsFormValueChanged(formValue: ReportsTemplateFormValue) {
        this.viewSettingsFormValue$.next(formValue);
    }

    onDataChanged(event: ReportsTemplateDataChangeEvent) {
        this.report = event.report;
        this.data = event.data;
    }

    onBenchmarkSelectionChanged(benchmarkName: string) {
        this.form.get('selectedBenchmark').setValue(benchmarkName);
    }

    onStoreFilterSelectionChanged(storeFilterValue: string) {
        this.form.get('selectedStore').setValue(storeFilterValue);
    }
}

function toViewSettings(formValue: Partial<ReportRankComparison>): ReportSettings {
    const viewSettings = new ReportSettings();
    viewSettings.rankComparison = {
        timeFilter: formValue.timeFilter,
        comparisonFilter: formValue.comparisonFilter,
        filters: formValue.filters,
        selectedBenchmark: formValue.selectedBenchmark,
        selectedStore: formValue.selectedStore,
    };
    return viewSettings;
}
