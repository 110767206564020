import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DrilldownFilterContext, MnbReportEntityDrilldownStepModel } from '@shared-lib/modules/reports/components/entity-drilldown/entity-drilldown.model';


@Component({
    selector: 'mnb-reports-entity-drilldown-steps',
    templateUrl: './mnb-reports-entity-drilldown-drilldown-steps.component.html'
})
export class MnbReportsEntityDrilldownDrilldownStepsComponent implements OnInit {

    /**
     * @deprecated
     */
    @Input() public drilldownSteps: MnbReportEntityDrilldownStepModel[] = [];
    /**
     * @deprecated
     */
    @Input() public drilldownEntity: MnbReportEntityDrilldownStepModel;
    @Input() public drilldownFiltersContext: DrilldownFilterContext[] = [];
    @Output() public drillup = new EventEmitter<MnbReportEntityDrilldownStepModel>();

    @Input()

    ngOnInit(): void {
    }

    onDrillup(stepModel: any) {
        this.drillup.next(stepModel);
    }

    onDrillupLegacy(stepModel: MnbReportEntityDrilldownStepModel) {
        this.drillup.next(stepModel);
    }
}
