import {Injectable, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {RoutingConfigGuard, RoutingAuthedGuard, RoutingUnauthedGuard} from './guards/routing-route.guards';
import { AuthModule } from '../auth/auth.module';
import { RoutingOverviewPageComponent } from '@minubo-portal/modules/routing/pages/overview/routing-overview-page.component';
import { BrowserModule } from '@angular/platform-browser';
import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { AppHeader } from '@minubo-portal/models/app-header.model';
import { ApiModule } from '../api/api.module';
import { DashboardsModule } from '../dashboards/dashboards.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReportsModule } from '@minubo-portal/modules/reports/reports.module';
import { RoutingSwitchPartnerPageComponent } from './pages/switch-partner/routing-switch-partner-page.component';
import { PortalInstanceService } from '@minubo-portal/services/portal-instance.service';

@Injectable({ providedIn: 'root' })
export class RoutingPortalAppHeaderResolver implements Resolve<AppHeader> {
    constructor(private portalInstanceService: PortalInstanceService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): AppHeader {
        return <AppHeader> {
            title: this.portalInstanceService.instanceConfig.name
        };
    }
}

const routes: Routes = [
    {
        path: '',
        component: RoutingOverviewPageComponent,
        resolve: {
            header: RoutingPortalAppHeaderResolver
        },
        canActivate: [RoutingAuthedGuard]
    },
    {path: 'login', loadChildren: () => AuthModule, canActivate: [RoutingUnauthedGuard]},
    {
        path: 'switchPartner',
        component: RoutingSwitchPartnerPageComponent,
        canActivate: [RoutingAuthedGuard]
    },
    {path: 'dashboards', loadChildren: () => DashboardsModule, canActivate: [RoutingAuthedGuard, RoutingConfigGuard]},
    {path: 'reports', loadChildren: () => ReportsModule, canActivate: [RoutingAuthedGuard, RoutingConfigGuard]},
];


@NgModule({
    declarations: [
        RoutingOverviewPageComponent,
        RoutingSwitchPartnerPageComponent
    ],
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false
        }),
        BrowserModule,
        ApiModule,
        MnbLibModule,
        TranslateModule
    ],
    exports: [RouterModule],
    providers: [RoutingAuthedGuard, RoutingUnauthedGuard, RoutingConfigGuard]
})
export class RoutingModule {

}
