import {NgModule} from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { ApiService } from './services/api.service';
import { ApiDashboardService } from './services/api-dashboard.service';
import { ApiAuthService } from './services/api-auth.service';
import { ApiSessionService } from './services/api-session.service';
import { ApiModelLoader } from './providers/api-model-loader.provider';
import { ApiConfigProvider } from './providers/api-config-provider.provider';
import { ApiDataService } from './services/api-data.service';
import { ApiPortalService } from './services/api-portal.service';
import { ApiReportService } from '@minubo-portal/modules/api/services/api-report.service';
import { ApiTrackingService } from '@minubo-portal/modules/api/services/api-tracking.service';

@NgModule({
    declarations: [],
    imports: [
        HttpClientModule,
        MnbLibModule
    ],
    providers: [
        ApiService,
        ApiPortalService,
        ApiDashboardService,
        ApiAuthService,
        ApiSessionService,
        ApiModelLoader,
        ApiDataService,
        ApiConfigProvider,
        ApiReportService,
        ApiTrackingService,
    ]
})
export class ApiModule {

}
