import { Component, OnInit } from '@angular/core';
import { PortalDashboard } from '@minubo-portal/modules/api/models/api-dashboard.model';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { ApiDashboardService } from '@minubo-portal/modules/api/services/api-dashboard.service';
import { ApiReportService } from '@minubo-portal/modules/api/services/api-report.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    templateUrl: './routing-overview-page.component.html'
})
export class RoutingOverviewPageComponent implements OnInit {

    constructor(
        private dashboardService: ApiDashboardService,
        private reportService: ApiReportService,
    ) {
    }

    public dashboards$: Observable<PortalDashboard[]>;

    public reports$: Observable<PortalReport[]>;


    private static sortByTitle<T extends {title: string}>(arr: T[]): void {
        arr.sort((a, b) => (a.title || '').localeCompare((b.title || '')));
    }

    ngOnInit() {
        this.dashboards$ = this.dashboardService.loadDashboards()
            .pipe(
                map(dashboards => {
                    RoutingOverviewPageComponent.sortByTitle(dashboards);
                    return dashboards;
                })
            );

        this.reports$ = this.reportService.loadReports()
            .pipe(
                map(reports => {
                    RoutingOverviewPageComponent.sortByTitle(reports);
                    return reports;
                })
            );
    }
}
