import { EntityType } from '@shared-lib/modules/data/model/mnb-data-entity.model';
import { QueryFilter, QuerySettingsComparisonFilter, QuerySettingsPlan, QuerySettingsSort, QuerySettingsTimeFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { MnbQuickTimeFilterValue } from '@shared-lib/modules/filters/components/quick-time-filter/mnb-quick-time-filter.component';
import deepEqual from 'deep-equal';
import { isNullOrUndefined } from 'util';
import { EntityPreset, EntitySchedule, User } from '@shared-lib/modules/data/model/mnb-data-entity-schedule.model';


export function getComparisonFilter(report: Report): QuerySettingsComparisonFilter | undefined {
    switch (report.typeCode) {
        case ReportTypeCode.ENTITY_DRILLDOWN:
            return report.settings.entityDrilldown.comparisonFilter;
        case ReportTypeCode.TABLE:
            return report.settings.table.comparisonFilter;
        case ReportTypeCode.TOP_LIST:
            return report.settings.topList.comparisonFilter;
        case ReportTypeCode.MEASURE_ATTRIBUTE_COMPARISON:
        case ReportTypeCode.MEASURE_TIME_COMPARISON:
        default:
            return undefined;
    }
}


export class Report {
    id?: number;
    title: string;
    description?: string;
    typeCode: ReportTypeCode;
    settings: ReportSettings;
    folder?: string;

    lastViewDateTime?: Date | number;
    lastViewMail?: string;
    lastEditDateTime?: Date | number;
    lastEditMail?: string;

    isPrivate?: boolean;
    isReadOnly?: boolean;

    fkLogin?: number;

    schedules: ReportSchedule[];
}

export enum ReportTypeCode {
    TOP_LIST = 'topList',
    MEASURE_TIME_COMPARISON = 'measureTimeComparison',
    MEASURE_ATTRIBUTE_COMPARISON = 'measureAttributeComparison',
    TABLE = 'table',
    ENTITY_DRILLDOWN = 'entityDrilldown',
    INVENTORY = 'inventory',
    RANK_COMPARISON = 'rankComparison',
    STORE_AREAS = 'storeAreas',
    EXPERIENCE_GROUP = 'experienceGroup',
}

export class ReportSettings {
    topList?: ReportSettingsTopList;
    measureTimeComparison?: ReportSettingsMeasureTimeComparison;
    measureAttributeComparison?: ReportSettingsMeasureAttributeComparison;
    table?: ReportSettingsTable;
    entityDrilldown?: ReportSettingsEntityDrilldown;
    inventory?: ReportSettingsInventory;
    rankComparison?: ReportRankComparison;
    storeAreas?: ReportStoreAreas;
    experienceGroup?: ReportExperienceGroup;

    static hasSameFilters(type: string, a: ReportSettings, b: ReportSettings): any {
        const isFiltersEmpty = (b[type].filters && !b[type].filters.length) || isNullOrUndefined(b[type].filters);
        const isCurrentFiltersEmpty = (a[type].filters && !a[type].filters.length) || isNullOrUndefined(a[type].filters);

        if (isFiltersEmpty || isCurrentFiltersEmpty) {
            return isFiltersEmpty === isCurrentFiltersEmpty;
        }

        return deepEqual(a[type].filters, b[type].filters);
    }
}

export class ReportSettingsMeasureAttributeComparison {
    measures?: Array<ReportSettingsMeasureAttributeComparisonMeasure>;
    attribute?: { code: string };
    timeFilter: QuerySettingsTimeFilter;
    filters?: Array<QueryFilter> = [];
    viewFilters?: Array<QueryFilter>;
    drilldownAttributes?: Array<{ code: string }> = [];
    sort: { measureCode?: string, attributeCode?: string, directionCode?: string };


    public static getNewSettings(): ReportSettingsMeasureAttributeComparison {
        return {
            measures: [],
            attribute: { code: null },
            timeFilter: QuerySettingsTimeFilter.getDefault(),
            filters: null,
            drilldownAttributes: [],
            sort: {}
        };
    }
}

export class ReportSettingsMeasureAttributeComparisonMeasure {
    code: string;
    settings: ReportSettingsMeasureAttributeComparisonMeasureSettings;
}

export class ReportSettingsMeasureAttributeComparisonMeasureSettings {
    isHighlight: boolean;
    isSupport: boolean;


    public static buildFromList(codes: string[]): ReportSettingsMeasureAttributeComparisonMeasureSettings {
        const settings = new ReportSettingsMeasureAttributeComparisonMeasureSettings();

        settings.isHighlight = codes.includes('highlight');
        settings.isSupport = !settings.isHighlight && codes.includes('support');
        return settings;
    }

    public static getList(settings: ReportSettingsMeasureAttributeComparisonMeasureSettings): string[] {
        if (!settings) {
            return [];
        }

        const codes = new Array<string>();
        if (settings.isHighlight) {
            codes.push('highlight');
        }
        if (settings.isSupport) {
            codes.push('support');
        }
        return codes;
    }
}

export class ReportTimeSetting {
    timeFilter: QuerySettingsTimeFilter;
    comparisonSettings: ReportComparisonSetting[] = [];
    columnSetting: ReportTimeColumnSetting;
    label?: string;

    public static getDefault(): ReportTimeSetting {
        return {
            timeFilter: QuerySettingsTimeFilter.getDefault(),
            comparisonSettings: [],
            columnSetting: {
                showPlanDeviation: true,
                showPlanValue: true
            }
        };
    }
}

export class ReportComparisonSetting {
    label?: string;
    comparisonFilter: QuerySettingsComparisonFilter;
    columnSetting: ReportComparisonColumnSetting;
}

export class ReportComparisonColumnSetting {
    showValue: boolean;
    showChange: boolean;

    public static toList(setting: ReportComparisonColumnSetting): string[] {
        const list: string[] = [];
        if (setting.showValue) {
            list.push('showValue');
        }
        if (setting.showChange) {
            list.push('showChange');
        }
        return list;
    }

    public static fromList(list: string[]): ReportComparisonColumnSetting {
        return {
            showValue: list.includes('showValue'),
            showChange: list.includes('showChange')
        };
    }
}

export class ReportTimeColumnSetting {
    showPlanValue: boolean;
    showPlanDeviation: boolean;

    public static toList(setting: ReportTimeColumnSetting): string[] {
        const list: string[] = [];
        if (setting.showPlanValue) {
            list.push('showPlanValue');
        }
        if (setting.showPlanDeviation) {
            list.push('showPlanDeviation');
        }
        return list;
    }

    public static fromList(list: string[]): ReportTimeColumnSetting {
        return {
            showPlanValue: list.includes('showPlanValue'),
            showPlanDeviation: list.includes('showPlanDeviation')
        };
    }
}

export class ReportSettingsMeasureTimeComparison {
    attribute: { code: string };
    plan?: QuerySettingsPlan;
    sort: { measureCode: string, directionCode?: string };
    filters?: Array<QueryFilter> = [];
    viewFilters?: Array<QueryFilter>;
    measures: Array<{ code: string }>;
    timeSettings: Array<ReportTimeSetting> = [];
    detailTimeSetting?: ReportTimeSetting;
}

export class ReportSettingsTopList implements MnbQuickTimeFilterValue {
    keyAttribute: { code: string };
    attributes: Array<{ code: string }> = [];
    measures: Array<ReportSettingsTableMeasure> = [];
    sort: { measureCode: string, directionCode?: string } = { measureCode: null };

    breakdownAttribute?: { code: string };

    timeFilter: QuerySettingsTimeFilter = QuerySettingsTimeFilter.getDefault();
    comparisonFilter?: QuerySettingsComparisonFilter;

    filters?: Array<QueryFilter> = [];
    viewFilters?: Array<QueryFilter>;
}

export class ReportSettingsEntityDrilldown implements MnbQuickTimeFilterValue {

    timeFilter: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
    filters?: QueryFilter[];

    timeSeriesIndex?: number;

    // New iteration below (Cleanup after migration is done)
    measureSets?: MeasureSet[];
    drilldownPathList?: DrilldownPath[];
    selectedDrilldownFilters?: QueryFilter[];
    selectedPathIdentifier?: string;
    selectedElementName?: string;
    selectedMeasureCodes?: string[];
}

export type MeasureSet = {
    measures: {code: string}[];
    conditions?: QueryFilter[];
};

export type DrilldownPath = {
    pathIdentifier: string;
    path: EntityDrilldownElement[];
};

export type EntityDrilldownElement = {
  elementName: string;
  keyAttributes?: {code: string}[];
  nameAttributes?: {code: string}[];
  drilldownSort?: QuerySettingsSort;
  conditions?: QueryFilter[];
};

export class ReportSettingsEntityDrilldownEntity {
    keyAttributes?: {code: string}[];
    nameAttributes?: {code: string}[];
    measures: {code: string}[];
    drilldowns: ReportSettingsEntityDrilldownDrilldown[];
    drilldownSort: QuerySettingsSort;
    views: ReportSettingsEntityDrilldownEntity[];
}

export class ReportSettingsEntityDrilldownDrilldown {
    keyAttributes: {code: string}[];
    nameAttributes: {code: string}[];
    measures: {code: string}[];
    name: string;
    sort: QuerySettingsSort;
}

export class ReportSettingsEntityDrilldownStep {

    drilldownIndex: number;
    viewIndex?: number;
    keyValues: {[code: string]: string};
    nameValues?: {[code: string]: string};
    entityIndex: number;
}

export class ReportStoreAreas {
    attributes?: {code: string}[];
    revenueMeasureCode?: string;
    filters?: QueryFilter[];
    timeFilter: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
    season?: ReportStoreAreasSeason;
    storeName?: string;
}

export class ReportExperienceGroup {
    filters?: QueryFilter[];
    breakdownAttributeCode?: string;
    subBreakdownAttributeCode?: string;
    measureCodes?: Array<string> = [];
    selectedExperienceGroup?: string;
    sort?: TableSortingState;
    timeFilter?: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
    selectedMeasureCodes?: string[];
}

export class TableSortingState {
    measureCode: string | null;
    directionCode: 'ASC' | 'DESC';
}

export class ReportStoreAreasSeason {
    name: string;
    yeat: string;
}

export class ReportRankComparison {
    timeFilter: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
    filters?: QueryFilter[];
    sort?: { measureCode: string, directionCode?: string };

    keyAttributeCode?: string;
    displayAttributeCodes?: string[];
    attributes?: Array<{ code: string }> = [];
    measures?: Array<{ code: string, displayMode: MeasureDisplayMode }> = [];

    isBenchmarkComparison?: boolean;
    isSortedByCompareRanks?: boolean;

    selectedBenchmark?: string;
    selectedStore?: string;
}

export enum MeasureDisplayMode {
    REL = 'REL',
    ABS = 'ABS'
}

export class ReportSettingsInventory {
    timeFilter: QuerySettingsTimeFilter;
    filters?: QueryFilter[];

    salesQtyMeasure?: { code: string };
    profitRateMeasure?: { code: string };

    reportHeadings?: {
        warehouseLabel?: string,
        itemLabel?: string,
    };
}

export class ReportSettingsTable implements MnbQuickTimeFilterValue {
    plan?: QuerySettingsPlan;
    sort?: TableSort;
    filters?: Array<QueryFilter> = [];
    activeDrilldownFilters?: Array<QueryFilter> = [];
    viewFilters?: Array<QueryFilter>;
    attributes?: Array<{ code: string }> = [];
    measures?: Array<ReportSettingsTableMeasure>;
    timeFilter?: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
    drilldownAttributes?: Array<{ code: string }> = [];
    availableDownloadAttributes?: Array<{ code: string }> = [];
    availableDownloadMeasures?: Array<{ code: string }> = [];
    selectedDownloadAttributeCodes?: string[] = [];
    selectedDownloadMeasureCodes?: string[] = [];

    public static getNewSettings(): ReportSettingsTable {
        return {
            measures: [],
            sort: { measureCode: null, directionCode: 'DESC' },
            attributes: [],
            timeFilter: QuerySettingsTimeFilter.getDefault(),
            selectedDownloadAttributeCodes: [],
            selectedDownloadMeasureCodes: [],
        };
    }
}

export type TableSort = {
    attributeCode?: string | null;
    measureCode?: string | null;
    directionCode: 'ASC' | 'DESC';
};

export class ReportSettingsTableMeasure {
    code: string;
    settings: ReportSettingsTableMeasureSettings;
}

export class ReportSettingsTableMeasureSettings {
    showValue?: boolean;
    showComparisonValue?: boolean;
    showComparisonChange?: boolean;
    showPlanValue?: boolean;
    showPlanDeviation?: boolean;
    showPercentageOfTotal?: boolean;

    public static buildFromList(list: Array<string>): ReportSettingsTableMeasureSettings {
        const settings = new ReportSettingsTableMeasureSettings();
        settings.showValue = true;
        // measure.showValue = list.includes('value');
        settings.showComparisonValue = list.includes('comparisonValue');
        settings.showComparisonChange = list.includes('comparisonChange');
        settings.showPlanValue = list.includes('planValue');
        settings.showPlanDeviation = list.includes('planDeviation');
        settings.showPercentageOfTotal = list.includes('percentageOfTotal');
        return settings;
    }

    public static buildList(settings: ReportSettingsTableMeasureSettings): string[] {
        const list: string[] = [];

        if (!settings) {
            return list;
        }

        if (settings.showValue) { list.push('value'); }
        if (settings.showComparisonValue) { list.push('comparisonValue'); }
        if (settings.showComparisonChange) { list.push('comparisonChange'); }
        if (settings.showPlanValue) { list.push('planValue'); }
        if (settings.showPlanDeviation) { list.push('planDeviation'); }
        if (settings.showPercentageOfTotal) { list.push('percentageOfTotal'); }

        return list;
    }
}

export class ReportData {
    topList?: ReportTopListData;
    table?: ReportTableData;
    entityDrilldown?: ReportEntityDrilldownData;
    measureTimeComparison?: ReportMeasureTimeComparisonData;
    measureAttributeComparison?: ReportMeasureAttributeComparisonData;
    inventory?: ReportInventoryData;
    rankComparison?: ReportRankComparisonData;
    storeAreas?: ReportStoreAreasData;
    experienceGroup?: ReportExperienceGroupData;
}

export class ReportMeasureTimeComparisonData {
    values: Array<ReportMeasureTimeComparisonDataValues>;
    breakdown: Array<{ attribute: string, values: Array<ReportMeasureTimeComparisonDataValues> }>;
}

export class ReportMeasureAttributeComparisonData {
    attribute?: string;
    values?: { [code: string]: number };
    breakdown?: Array<ReportMeasureAttributeComparisonData>;
    fromDate?: Date;
    toDate?: Date;
}

export class ReportTopListData {
    rows: Array<ReportTopListRow>;
    breakdown?: Array<{ breakdownAttribute: string, rows: Array<ReportTopListRow> }>;
}

export class ReportTopListRow {
    keyAttribute: string;
    position: number;
    comparePosition: number;

    attributes: { [attributeCode: string]: string };

    values: { [measureCode: string]: number };
    compareValues: { [measureCode: string]: number };
}

export class ReportTableData {
    rows: Array<ReportTableRow>;
    values: { [measureCode: string]: number };
    compareValues: { [measureCode: string]: number };
    hasMore: boolean;
    estimatedTotalRows: number;
}

export class ReportTableRow {
    attributes: { [code: string]: string };
    values: { [code: string]: number };
    compareValues: { [code: string]: number };
}

export class ReportDataValues {
    attribute: string;
    fromDate: Date | number;
    toDate: Date | number;
    values: { [measureCode: string]: number };
}

export class ReportMeasureTimeComparisonDataValues extends ReportDataValues {
    comparisons?: Array<ReportMeasureTimeComparisonDataValues> = [];
}

export class ReportEntityDrilldownData {
    entity: ReportEntityDrilldownEntityData;
    drilldownRows?: ReportEntityDrilldownRowData[];
    timeSeries?: ReportDrilldownTimeSeriesEntry[];
}

export type ReportDrilldownTimeSeriesEntry = {
    timeAttribute: string;
    values: { [measureCode: string]: number };
    compareValues: { [measureCode: string]: number };
};

export class ReportEntityDrilldownEntityData {
    attributes?: { [attributeCode: string]: string };
    values: { [measureCode: string]: number };
    compareValues?: { [measureCode: string]: number };
    views: ReportEntityDrilldownEntityData[];
}

export class ReportEntityDrilldownRowData {

    attributes: { [code: string]: string };
    values: { [measureCode: string]: number };
    compareValues: { [measureCode: string]: number };
}

export class ReportInventoryData {
    inventoryMeasureCode: string;
    salesQtyMeasureCode: string;
    profitRateMeasureCode: string;
    products: ReportInventoryProductData[];
    warehouseOverview: ReportInventoryProductData;
}

export class ReportStoreAreasData {
    rows: ReportStoreAreasDataRow[];
    seasons: ReportStoreAreasDataSeason[];
    selectedSeason: ReportStoreAreasDataSeason;
    comparisonSeason: ReportStoreAreasDataSeason;
}

export type ReportStoreAreasDataRow = {
    attributes: { [attributeCode: string]: string };
    values: { [measureCode: string]: number };
    comparisonValues: { [measureCode: string]: number };
};

export type ReportStoreAreasDataSeason = {
    name: string;
    year: string;
};

export class ReportExperienceGroupData {
    availableExperienceGroups: string[];
    rows: ReportExperienceGroupDataRow[];
    subRowsMap: {[breakdownAttributeValue: string]: ReportExperienceGroupDataRow[]};
}

export type ReportExperienceGroupDataRow = {
    breakdownAttributeValue: string;
    values: { [measureCode: string]: number };
    comparisonValues: { [measureCode: string]: number };
};

export class ReportRankComparisonData {
    rows: ReportRankComparisonDataRow[];
    availableBenchmarks: string[];
    availableStores: string[];
}

export type ReportRankComparisonDataRow = {
    keyAttribute: string;
    displayAttributeValues: string[];
    rank: number;
    comparisonRank: number;
    compareRank: number;
    compareComparisonRank: number;
    attributes: { [attributeCode: string]: string };
    values: { [measureCode: string]: number };
    comparisonValues: { [measureCode: string]: number };
    compareChanges: { [measureCode: string]: number };
    compareValues: { [measureCode: string]: number };
};

export class ReportInventoryProductData {
    number: string;
    name: string;
    displayValues: string[];
    totals: ReportInventoryProductTotalsData;

    inventory: number;
    salesQty: number;
    profitRate: number;

    sizes: ReportInventoryProductSizeData[];
    variations: ReportInventoryProductVariationData[];
}

export class ReportInventoryProductTotalsData {
    totalInventory: number;
    totalSalesQty: number;
    sizes: ReportInventoryProductTotalsSizeData[];
}

export class ReportInventoryProductTotalsSizeData {
    name: string;
    sortCode?: string;
    totalInventory: number;
    totalSalesQty: number;
}

export class ReportInventoryProductVariationData {
    name: string;
    totalInventory: number;
    totalSalesQty: number;

    inventory: number;
    salesQty: number;
    profitRate: number;
    sizes: ReportInventoryProductSizeData[];
}

export class ReportInventoryProductSizeData {
    name: string;
    sortCode?: string;
    inventory: number;
    salesQty: number;
    profitRate: number;
}

export type ReportPreset = EntityPreset<{ report: Report }>;

export class ReportSchedule extends EntitySchedule {

    public report?: Report;

    constructor(
        public id: number,
        public fkReport?: number,
        public frequency?: string,
        public currentRunDateTime?: number,
        public nextRunDateTime?: number,
        public recipients?: User[],
        public settings?: any,
        public isDeactivated?: boolean,
        public statusCode?: string,
        public targetTime?: string,
        public entityTitle?: string
        ) {
            super(id, frequency, currentRunDateTime, nextRunDateTime, recipients, settings, entityTitle, EntityType.REPORT, isDeactivated, statusCode, targetTime);
        }

    public getFkEntity(): number {
        return this.fkReport;
    }
}

export class ReportDownload {
    public isReady: boolean;
    public uuid: string;
    public viewSettings?: ReportSettings;
}
